.list-data {
    list-style: none;
    text-decoration: none;
    padding-right: 20px;
}

.list-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Nunito;
}

.hamburger-icon {
    display: none;
}

.nav-list {
    display: none;  
}

.nav-container {
    display: flex;
    justify-content: space-between;
    background-color: var(--backgroundColor);
}

.logo-image {
    height: 50px;
    margin: 5px;
}

.link {
    padding: 10px;
    text-decoration: none;
    border-radius: 0.2rem;
    transition: 0.5s;
    color: var(--white)
}

.link:hover {
    color: var(--backgroundColor);
    background-color: var(--white);
}

.show-selection {
    background-color: var(--secondaryColor);
    color: var(--backgroundColor)
}

.hide-selection {
    color: var(--white)
}

.static {
    position:absolute;
    background: white;
}

.static:hover {
    opacity:0;
}
@media screen and (max-width: 748px) {
    .hamburger-icon {
        display: block;
    }
    .list-data {
        display: none;
    }
    .nav-list {
        display: block;  
        position: absolute;
        background: var(--backgroundColor);
        transform: translate(0, -60px);
        z-index: 3;
        width: 100%;
        height:120%;
    }
    .nav-list ul {
        padding: 30px 0;
        z-index: 4;
        width: 100%;
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }
    .crossIcon {
        width: 10%;
        position: absolute;
        right: 5px;    
    }
    .nav-list ul li {
        color: aliceblue;
        list-style: none;
        text-decoration: none;
    }
}