.languagesPage-container {
    color: var(--textColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.background-box-lang {
    z-index: 0;
    margin: 50px 0;
    background-size: 40px 40px;
    background-color: black;
    box-shadow: 0px 0px 40px 20px var(--secondaryBlueColor);
    padding:50px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.top-Section-lang {
    width: 648px;
    max-width: 648px;
    display:flex;
    flex-direction: row;
    justify-content:space-around;
    align-items:center;

}
.dial-container {
    height: 324px;
    max-height: 324px;
    max-width: 324px;
    width: 310px;
    background:red;
}

.notes-data-container {
    height: 324px;
    max-height: 324px;
    max-width: 324px;
    width: 310px;
    background: green;
}

.menu-toggler {
    position: absolute;
    display: block;
    background: blue;
    margin:130px;
    /* top:0;
    bottom:0;
    right:0;
    left:0; */
    /* margin:auto; */
    width:40px;
    height:40px;
    z-index:4;
    opacity:0.5;
    cursor:pointer;
    /* background-image: url("../assets/minimize-icon.svg"); */
}

/* .menu-toggler:hover + label,
.menu-toggler:hover + label:before,
.menu-toggler:hover + label:after,{
background: white;
} */

/* .menu-toggler:checked + label {
background: transparent;
} */

/* .menu-toggler:checked + label:before,
.menu-toggler:checked + label:after,{
top:0;
width:40px;
transform-origin: 50% 50%;
} */

/* .menu-toggler:checked + label:before {
transform: rotate(45deg) translateY(-15px) translateX(-15px);
} */

/* .menu-toggler:checked + label:after {
transform: rotate(-45deg);
} */

.menu-toggler:checked ~ ul .menu-item { 
opacity: 1;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(1) {
transform: rotate(0deg) translate(-110px);
}

.menu-toggler:checked ~ ul .menu-item:nth-child(2) {
transform: rotate(60deg) translateX(-110px);
}

.menu-toggler:checked ~ ul .menu-item:nth-child(3) {
transform: rotate(120deg) translateX(-110px);
}

.menu-toggler:checked ~ ul .menu-item:nth-child(4) {
transform: rotate(180deg) translateX(-110px);
}

.menu-toggler:checked ~ ul .menu-item:nth-child(5) {
transform: rotate(240deg) translateX(-110px);
}

.menu-toggler:checked ~ ul .menu-item:nth-child(6) {
transform: rotate(300deg) translateX(-110px);
}

.menu-toggler:checked ~ ul .menu-item a {
pointer-events:auto;
}

/* .menu-toggler + label {
width: 40px;
height: 5px;
display: block;
z-index: 2;
border-radius: 2.5px;
background: rgba(230, 239, 250, 0.9);
transition: transform 0.5s top 0.5s;
position: absolute;
display: block;
top: 0;
bottom: 0;
left: 0;
right: 0;
margin: auto;
}

.menu-toggler + label:before,
.menu-toggler + label:after {
width: 40px;
height: 5px;
display: block;
z-index: 2;
border-radius: 2.5px;
background: rgba(255, 255, 255, 0.7);
transition: transform 0.5s top 0.5s;
content: "";
position: absolute;
display: block;
left: 0;
} */

.menu-toggler + label:before {
top: 10px;
}

.menu-toggler + label:after {
top: -10px;
}

.menu-item:nth-child(1) a {
transform: rotate(0deg);
}

.menu-item:nth-child(2) a {
transform: rotate(-60deg);
}

.menu-item:nth-child(3) a {
transform: rotate(-120deg);
}

.menu-item:nth-child(4) a {
transform: rotate(-180deg);
}

.menu-item:nth-child(5) a {
transform: rotate(-240deg);
}

.menu-item:nth-child(6) a {
transform: rotate(-300deg);
}

.menu-item {
position: absolute;
display: block;
/* top: 0;
bottom: 0;
left: 0;
right: 0; */
/* margin: auto; */
width: 80px;
align-items:center;
height: 80px;
opacity: 0;
transition: 0.5s;
z-index: 2;
}
.menu-list-item {
    margin-top: 120px;
    margin-left: 70px;
}

/* .menu-item a {
display: block;
width: inherit;
height: inherit;
line-height: 80px;
color: rgba(255, 255, 255, 0.7);
background: rgba(230, 230, 250, 0.7);
border-radius: 50%;
text-align: center;
text-decoration: none;
font-size: 40px;
pointer-events: none;
transition: 0.2s;
} */

.menu-item a:hover {
box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
color: white;
background: rgba(255, 255, 255, 0.3);
font-size: 44.44px
}