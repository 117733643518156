.home-main {
    background: var(--backgroundColor);
}

.homePage-container {
    color: var(--textColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top-Section {
    z-index: 5;
}

.article {
    font-size: 10px;
    font-family: Nunito;
    z-index: 1;
    padding: 0 30px;
    background: -webkit-linear-gradient(var(--secondaryColor) 2%, var(--primaryColor) 98%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.background-box {
    z-index: 0;
    margin: 50px 0;
    background-size: 40px 40px;
    background-color: black;
    box-shadow: 0px 0px 40px 20px var(--secondaryBlueColor);
    padding:50px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.homeText {
    margin: 0;
}

.helloTitle {
    font-size: 24px;
}

.myName {
    font-size: 44px;
}

.myJobTitle {
    font-size: 24px;
    display: flex;
    color: var(--primaryColor);
}

.content-description {
    font-size: 16px;
    width: 40vw;
    min-width: 40vw;
}

.myJobTitle>p {
    margin: 0 1vw 0 0;
    color: var(--textColor);
}

.image-div {
    height: 100%;
    max-height: 324px;
    max-width: 324px;
    width: auto;
}

.myImage {
    height: 100%;
    max-height: 324px;
    max-width: 324px;
    width: auto;
    border-radius: 100%;
    margin-right: 30px;
    object-fit: cover;
    border: 0.5px var(--secondaryBlueColor) solid;
    box-shadow: 0px 0px 40px 1px var(--secondaryBlueColor);
}

.image-card {
    transition: transform 1500ms;
    transform-style: preserve-3d;
}

.image-div:hover > .image-card {
    transform: rotateY(180deg);
}

.img-text-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateY(180deg);
    background-color: rgba(7, 2, 13, 0.6);
    height: 100%;
    border-radius: 100%;
    max-height: 324px;
    max-width: 324px;
    width: 310px;
    backface-visibility: hidden;
}

.image-container-div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
}

.contact-image {
    width: 40px;
    padding: 5px;
}

.connection-div > h3  {
    text-align: center;
    color: var(--textColor);
}

@keyframes typing {
    from { width: 0 }
}

@keyframes blink-caret {
    50% { border-color: transparent }
}

.scrollToTopContainer {
    position: fixed;
    bottom: 10px;
    right: 10px;
}
.scrollToTopButton {
    background-color: var(--backgroundColor);
    border: none;
}
.scroll-to-top-icon {
    background-color: var(--backgroundColor);
    width: 30px;
    height: 30px;
}

@media screen and (max-width: 748px) {
    .article {
        padding: 0;
    }

    .myImage {
        max-height: 124px;
        max-width: 124px;
        margin-right: 0;
    }

    .background-box {
        flex-direction: column-reverse;
    }

    .homeText {
        text-align: center;
    }

    .helloTitle {
        text-align: center;
    }
    
    .myName {
        text-align: center;
    }
    .myJobTitle {
        display: flex;
        justify-content: center;
    }
    
    .content-description {
        text-align: center;
        margin: 0;
        width: auto;
        min-width: auto;
    }

    .img-text-container {
        width: 120px;
        height: 125px;
    }

    .contact-image {
        width: 20px;
        padding: 5px;
    }

    .connection-div > h3  {
    font-size: 10px;
    text-align: center;
    }
}