:root {
    --backgroundColor: #07020dff;
    --secondaryColor: #5db7deff;
    --textColor: #f1e9dbff;
    --midColor: #a39b8bff;
    --primaryColor: #716a5cff;
    --white: #f7ebeb;
    --secondaryBlueColor: #0ff;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--backgroundColor); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--secondaryColor); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor); 
  }